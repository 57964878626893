import {unitsPrefix} from 'constants/unitsPrefix';

export const parseUnits = (unit: string): string => {

  for (const prefix of unitsPrefix) {
    if (unit.startsWith(prefix)) {
      return unit.substring(prefix.length);
    }
  }

  return unit;
}