import { AlertInterval, ParsedData } from 'types';

export const getAlertIntervals =
    (data: ParsedData[], maxValue: number): AlertInterval[] => {
      const alertIntervals: AlertInterval[] = [];

      for (let i = 0; i < data.length;) {
        if (data[i].x >= maxValue) {
          for (let j = i; j < data.length; j++) {
            if (data[j].x < maxValue) {
              alertIntervals.push({ start: data[i], end: data[j] });
              i = j + 1;
              break;
            }

            if (j === data.length - 1) {
              alertIntervals.push({ start: data[i], end: data[j] });
              i = j + 1;
              break;
            }
          }
          continue;
        }

        i++;
      }
      return alertIntervals;
    }