import {PanelPlugin} from '@grafana/data';
import {D3LineChart} from 'components/D3LineChart';

import {CustomOptions} from './types';

export const plugin = new PanelPlugin<CustomOptions>(D3LineChart)
                          .setPanelOptions((builder) => {
                            return builder
                                .addBooleanSwitch({
                                  path: 'showYAxis',
                                  name: 'Show Y Axis (time)',
                                  defaultValue: true,
                                })
                                .addNumberInput({
                                  path: 'pointsRadius',
                                  name: 'Points Radius',
                                  defaultValue: 2.5,
                                })
                                .addNumberInput({
                                  path: 'lineWidth',
                                  name: 'Line Width',
                                  defaultValue: 1,
                                });
                          })
                          .useFieldConfig({
                            useCustomConfig: (builder) => {
                              return builder
                                  .addNumberInput({
                                    path: 'alarmValue',
                                    name: 'Alarm Value',
                                    defaultValue: 99999999,
                                  })
                                  .addTextInput({
                                    path: 'alarmTitle',
                                    name: 'Alarm Title',
                                    defaultValue: 'Alarm',
                                  })
                                  .addTextInput({
                                    path: 'alarmDescription',
                                    name: 'Alarm Description',
                                    defaultValue: 'Alarm Description',
                                  })
                                  .addColorPicker({
                                    path: 'alarmColor',
                                    name: 'Alarm Color',
                                  });
                              ;
                            }
                          });
