export const closestPoint = (input: number, data: number[]): number | null => {
  if (data.length === 0) {
    return null; // Manejo de data vacío, puedes ajustar según tus necesidades.
  }

  let closestPointIndex = 0;
  let minorDifference = Math.abs(input - data[0]);

  for (let i = 1; i < data.length; i++) {
    const actualDifference = Math.abs(input - data[i]);

    if (actualDifference < minorDifference) {
      minorDifference = actualDifference;
      closestPointIndex = i;
    }
  }

  return data[closestPointIndex];
}