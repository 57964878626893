export const unitsPrefix: string[] = [
  'force',
  'length',
  'mass',
  'time',
  'acc',
  'area',
  'currency',
  'date',
  'flow',
  'press',
  'rad',
  'rot',
  'velocity',
  'bool'
]