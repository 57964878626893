import React, { useEffect, useRef, useState } from 'react';
import { AlarmState, AlertInterval, CustomOptions } from 'types';
import * as d3 from 'd3';
import { PanelProps, Field } from '@grafana/data';
import { LegendDisplayMode, VizLegend, VizLegendItem, Alert } from '@grafana/ui';
import { getColor } from 'utils/getColor';
import { parseUnits } from 'utils/parseUnits';
import { getAlertIntervals } from 'utils/getAlertIntervals';
import { closestPoint } from 'utils/closestPoint';

interface Props extends PanelProps<CustomOptions> {}

interface ParsedData {
  x: number;
  y: number;
}

interface XData {
  sacalarLinear: d3.ScaleLinear<number, number, never>;
  data: Field;
  xVSy: ParsedData[];
  legendItems: VizLegendItem;
  alertIntervals: AlertInterval[];
}

export const D3LineChart: React.FC<Props> = ({ options, data, width, height, onChangeTimeRange }) => {
  const marginTop = 10;
  const marginRight = 10;
  const marginBottom = 30;
  const marginLeft = options.showYAxis ? 40 : 10;
  const aspectRatio = 43;
  const xLabelTicks = width / aspectRatio;
  const firstSeries = data.series[0];

  let filteredXArr = firstSeries.fields.filter((objeto) => objeto.type === 'number');
  let filteredYArr = firstSeries.fields.filter((objeto) => objeto.type === 'time');

  const xData: XData[] = [];
  const [labelData, setLabelData] = useState<VizLegendItem>();
  const [alertNotification, setAlertNotification] = useState<AlarmState[]>([]);

  if (xData.length === 0) {
    for (const field of filteredXArr) {
      const parsedData: ParsedData[] = [];
      const yArrData = firstSeries.fields
        .filter((field) => field.type === 'time')
        .map((field) => field.values)
        .flat();

      const xArrData = field.values.flat();

      for (const i in xArrData) {
        parsedData.push({ x: xArrData[i], y: yArrData[i] });
      }

      const alertIntervals = getAlertIntervals(parsedData, field.config.custom?.alarmValue ?? 0);
      if (alertIntervals.length > 0 && alertNotification.length === 0) {
        alertNotification.push({
          display: true,
          title: field.config.custom?.alarmTitle ?? '',
          description: field.config.custom?.alarmDescription ?? '',
          lastEvent: alertIntervals[alertIntervals.length - 1],
        });
      } else if (alertIntervals.length > 0) {
        alertNotification.map((alert) => {
          if (
            alert.title === field.config.custom?.alarmTitle &&
            alert.lastEvent.start.y !== alertIntervals[alertIntervals.length - 1].start.y
          ) {
            alert.display = true;
            alert.lastEvent = alertIntervals[alertIntervals.length - 1];
          }
        });
      }

      xData.push({
        data: field,
        sacalarLinear: d3.scaleLinear(),
        xVSy: parsedData,
        alertIntervals: alertIntervals,
        legendItems: {
          yAxis: 1,
          color: field.config.color?.fixedColor ?? getColor(filteredXArr.indexOf(field)),
          label: field.name,
          disabled: true,
        },
      });
    }
  }

  if (labelData) {
    const index = xData.findIndex((x) => x.data.name === labelData.label);
    xData[index].legendItems.disabled = !labelData.disabled;
  }

  const alertBanners = alertNotification.map((alert, index) => {
    if (alert.display) {
      return (
        <div key={alert.title} style={{ position: 'absolute', top: -30 }}>
          <Alert
            style={{ width: width, display: 'flex' }}
            title={alert.title}
            severity="error"
            buttonContent="Close"
            key={alertNotification.indexOf(alert)}
            onRemove={() => {
              alert.display = false;
              const newAlertNotification = alertNotification.map((alert, i) => {
                if (i === index) {
                  return alert;
                }
                return alert;
              });
              setAlertNotification(newAlertNotification);
            }}
          />
        </div>
      );
    }
    return null;
  });

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      d3.select(chartRef.current).selectAll('*').remove();

      for (const xAxis of xData) {
        const x = d3
          .scaleLinear()
          .domain(d3.extent([...xAxis.data.values]) as [number, number])
          .range([marginLeft, width - marginRight])
          .nice();

        if (!xAxis.data.config.color?.fixedColor && xAxis.data.config.color) {
          xAxis.data.config.color.fixedColor = getColor(xData.indexOf(xAxis));
        }
        xAxis.sacalarLinear = x;
      }

      const [minTime, maxTime] = d3.extent([...filteredYArr[0].values]) as [Date, Date];
      const y = d3
        .scaleTime()
        .domain([maxTime, minTime])
        .range([height - marginBottom - 25, marginTop])
        .nice();

      const brushed = (event: any) => {
        if (event.selection) {
          const [y0, y1] = event.selection;
          const y0Value = y.invert(y0).getTime();
          const y1Value = y.invert(y1).getTime();

          onChangeTimeRange({ from: y0Value, to: y1Value });
        }
      };

      const brush = d3
        .brushY()
        .extent([
          [marginLeft, marginTop],
          [width - marginRight, height - marginBottom],
        ])
        .on('end', brushed);

      const randomId = `g-chart-${Math.floor(Math.random() * 10000000)}`;
      d3.select(chartRef.current)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('id', randomId);
      const svg = d3.select(`#${randomId}`);

      const crosshairX = svg
        .append('line')
        .attr('class', 'crosshair crosshair-x')
        .attr('stroke', 'white')
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '2,2');

      const crosshairY = svg
        .append('line')
        .attr('class', 'crosshair crosshair-y')
        .attr('stroke', 'white')
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '2,2');

      svg
        .on('mousemove', (event) => {
          let [x, y0] = d3.pointer(event);
          const userAgent = navigator.userAgent;
          if (userAgent.includes('Firefox')) {
            x = event.layerX - 7.5;
            y0 = event.layerY - 7.5;
          }

          const closestPoints = [];
          for (const xp of xData) {
            const closestXPoint = closestPoint(
              y0,
              xp.xVSy.map((d) => y(d.y))
            );
            closestPoints.push(closestXPoint);
          }

          if (x < 0 || y0 < 0) {
            return;
          }

          svg
            .selectAll('.focused-data-point')
            .attr('r', options.pointsRadius)
            .attr('filter', 'none')
            .attr('class', 'data-point');
          svg
            .selectAll(`circle[cy='${closestPoints[0]}']`)
            .attr('r', 6)
            .attr('filter', 'drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.5))')
            .attr('class', 'data-point focused-data-point');

          svg.attr('cursor', 'crosshair');

          crosshairX
            .style('display', 'inline')
            .attr('x1', x)
            .attr('x2', x)
            .attr('y1', marginTop)
            .attr('y2', height - marginBottom - 20);
          crosshairY
            .style('display', 'inline')
            .attr('x1', marginLeft)
            .attr('x2', width - marginRight)
            .attr('y1', y0)
            .attr('y2', y0);
        })
        .on('mouseleave', () => {
          crosshairX.attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', 0);
          crosshairY.attr('y1', 0).attr('y2', 0).attr('x1', 0).attr('x2', 0);
          crosshairX.style('display', 'none');
          crosshairY.style('display', 'none');
          svg
            .selectAll('.focused-data-point')
            .attr('r', options.pointsRadius)
            .attr('filter', 'none')
            .attr('class', 'data-point');
        });

      for (const x of xData) {
        svg
          .append('g')
          .attr('class', `x-axis x-axis-${xData.indexOf(x)}`)
          .attr('transform', `translate(0,${height - marginBottom - 20})`)
          .attr('opacity', x.legendItems.disabled ? 0 : 1)
          .call(d3.axisBottom(x.sacalarLinear).ticks(xLabelTicks))
          .selectAll('line')
          .attr('opacity', 0);

        svg
          .select(`g.x-axis-${xData.indexOf(x)}`)
          .append('g')
          .attr('class', 'units')
          .attr('opacity', 1)
          .attr('transform', `translate(${width / 2},29)`)
          .append('text')
          .attr('fill', 'currentColor')
          .attr('color', `${x.data.config.color?.fixedColor}`)
          .text(`[${parseUnits(x.data.config.unit ?? '')}]`);
      }

      svg.selectAll('g.x-axis .domain').attr('stroke-width', '0');

      svg
        .append('g')
        .attr('class', 'y-axis')
        .attr('transform', `translate(${marginLeft},0)`)
        .call(d3.axisLeft(y).tickSize(0).tickPadding(10));

      svg.selectAll('g.y-axis .domain').attr('stroke-width', '0');

      svg
        .selectAll('g.y-axis g.tick')
        .append('line')
        .attr('class', 'gridline')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', width - marginLeft - marginRight)
        .attr('y2', 0)
        .attr('stroke', '#9ca5aecf')
        .attr('opacity', 0.1);

      svg
        .selectAll('g.x-axis g.tick')
        .append('line')
        .attr('class', 'gridline')
        .attr('x1', 0)
        .attr('y1', -height + marginBottom)
        .attr('x2', 0)
        .attr('y2', 0)
        .attr('stroke', '#9ca5aecf')
        .attr('opacity', 0.1);

      if (!options.showYAxis) {
        svg.selectAll('.y-axis g.tick text').attr('color', 'rgba(0,0,0,0)');
      }

      svg.append('g').call(brush);

      for (const graphic of xData) {
        const alertValue = graphic.data.config.custom?.alarmValue ?? 0;
        for (const interval of graphic.alertIntervals) {
          if (alertValue !== 0) {
            const alarmHeight = y(interval.end.y) - y(interval.start.y);

            svg
              .append('rect')
              .attr('class', `alarm-interval-${graphic.alertIntervals.indexOf(interval)}`)
              .attr('x', marginLeft)
              .attr('y', y(interval.start.y))
              .attr('width', width - marginLeft - marginRight)
              .attr('height', alarmHeight)
              .style('fill', `${graphic.data.config.custom.alarmColor || graphic.data.config.color?.fixedColor}`)
              .attr('stroke', `${graphic.data.config.custom.alarmColor || graphic.data.config.color?.fixedColor}`)
              .attr('stroke-width', 0.5)
              .attr('stroke-opacity', 1)
              .attr('fill-opacity', 0.2);

            svg
              .append('text')
              .attr('x', marginLeft + 10)
              .attr('y', y(interval.start.y) + 10)
              .text(`${graphic.data.config.custom?.alarmTitle}`)
              .style('fill', 'white')
              .style('font-size', '12px')
              .attr('class', `alarm-text-${graphic.alertIntervals.indexOf(interval)}`)
              .on('mouseover', (event, d) => {
                tooltip.transition().duration(100).style('opacity', 1);
                tooltip
                  .html(`${graphic.data.config.custom?.alarmTitle}<br>${graphic.data.config.custom?.alarmDescription}`)
                  .style('left', event.pageX + 5 + 'px')
                  .style('top', event.pageY - 28 + 'px')
                  .style('display', 'inline')
                  .style('background', 'rgb(34, 37, 43)')
                  .style('border-radius', '2px')
                  .style('padding', '5px')
                  .style('box-shadow', 'rgba(1, 4, 9, 0.75) 0px 4px 8px');
              })
              .on('mouseout', () => {
                tooltip.transition().duration(100).style('display', 'none');
              });

            svg
              .selectAll(`.alarm-text-${graphic.alertIntervals.indexOf(interval)}`)
              .data([interval])
              .enter()
              .append('text')
              .attr('x', marginLeft + 10)
              .attr('y', y(interval.start.y) + 10)
              .text(`${graphic.data.config.custom?.alarmTitle}`)
              .style('fill', 'white')
              .style('font-size', '12px');
          }
        }

        const line = d3
          .line<ParsedData>()
          .x((dataPoint) => graphic.sacalarLinear(dataPoint.x) ?? 0)
          .y((dataPoint) => y(dataPoint.y));

        svg
          .append('path')
          .attr('class', 'line line-' + xData.indexOf(graphic))
          .data([graphic.xVSy])
          .attr('fill', 'none')
          .attr('stroke', `${graphic.data.config.color?.fixedColor}`)
          .attr('stroke-width', options.lineWidth)
          .attr('d', line)
          .style('pointer-events', 'all');

        svg
          .selectAll('.line-' + xData.indexOf(graphic))
          .data(graphic.xVSy)
          .enter()
          .append('circle')
          .attr('class', 'data-point')
          .attr('cx', (d) => graphic.sacalarLinear(d.x) ?? 0)
          .attr('cy', (d) => y(d.y) ?? 0)
          .attr('r', options.pointsRadius)
          .attr('fill', `${graphic.data.config.color?.fixedColor}`)
          .on('mouseover', (event, d) => {
            const parsedX = Number(d.x).toFixed(2);

            const date = new Date(d.y);
            const otherHour = new Intl.DateTimeFormat('en-ES', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: true,
            }).format(date);

            tooltip.transition().duration(100).style('opacity', 1);
            tooltip
              .html(
                `${otherHour}<br>${parsedX}[${parseUnits(graphic.data.config.unit ?? '')}]<span style="color:${
                  graphic.data.config.color?.fixedColor
                }"> ${graphic.data.name}</span>`
              )
              .style('left', event.pageX + 5 + 'px')
              .style('top', event.pageY - 28 + 'px')
              .style('display', 'inline')
              .style('background', 'rgb(34, 37, 43)')
              .style('border-radius', '2px')
              .style('padding', '5px')
              .style('box-shadow', 'rgba(1, 4, 9, 0.75) 0px 4px 8px');
          })
          .on('mouseout', () => {
            tooltip.transition().duration(100).style('display', 'none');
          });
      }

      const tooltipCheck = d3.select('.remasa-custom-tooltip');
      let tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
      if (tooltipCheck.empty()) {
        d3.select('body').append('div').attr('class', 'tooltip remasa-custom-tooltip').style('display', 'none');
        tooltip = d3.select('.remasa-custom-tooltip');
      } else {
        tooltip = tooltipCheck;
      }
    }
  }, [data, height, width]);

  return (
    <>
      <div ref={chartRef} />
      <div style={{ position: 'absolute', bottom: 10, left: 10 }}>
        <VizLegend
          onLabelClick={(label) => {
            setLabelData(label);
          }}
          displayMode={LegendDisplayMode.List}
          placement={'bottom'}
          items={xData.map((x) => x.legendItems)}
        />
      </div>
      {alertBanners || null}
    </>
  );
};
